// Make WheelAPI globally available
var WheelAPI = WheelAPI || false;

(function ($) {
  WheelAPI = {
    settings: {
      url: 'index.php/ozracing/wheels/'
    },
    get: function (ressource, params, callback) {
      var api = this;
      params = params != null ? params : {};

      $.post(api.settings.url + ressource, params, function (data) {
        callback(data);
      });
    }
  }
})(jQuery);